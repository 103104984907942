$(function() {
  $('[data-js-toggle-required-trigger]').click(function() {
    var root_target = $(this).data('jsToggleRequiredTrigger');
    var targets = $('[data-js-toggle-required-target=' + root_target + '] :input');

    targets.each(function(index){
      if (targets[index].hasAttribute("required")) {
        targets[index].removeAttribute('required');
      } else {
        targets[index].setAttribute("required", true)
      }
    })
  });
});
