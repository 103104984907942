$(function() {
  $('[data-js-accordion-trigger]').on('click', function() {
    var $Accordion = $(this).parent('[data-js-accordion]');

    $(this).attr('aria-expanded', function(i,attr) {
      return attr == 'true' ? 'false' : 'true';
    });

    $Accordion.find('[data-js-accordion-icon]').toggleClass('is-visible');
    $Accordion.find('[data-js-accordion-target]').toggleClass('is-expanded');
  });
});
