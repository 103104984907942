$(function() {
  $('[data-js-dropdown-trigger]').on('click', function() {
    var $parent = $(this).parents('[data-js-dropdown]'),
        $target = $parent.find('[data-js-dropdown-target]');
        $trigger = $parent.find('[data-js-dropdown-trigger]');

    if ($target.attr('data-js-dropdown-target') == 'open') {
      // Close curent dropdown
      $parent.removeClass('is-active');
      $target.attr('data-js-dropdown-target','closed');
      $trigger.attr('aria-expanded','false');

    } else {
      // Close all open dropdowns
      $('[data-js-dropdown]').removeClass('is-active');
      $('[data-js-dropdown-target=open]').attr('data-js-dropdown-target','closed');
      // Open current dropdown
      $parent.addClass('is-active');
      $target.attr('data-js-dropdown-target','open');
      $trigger.attr('aria-expanded','true');
    }
  });
});
