$(function() {
  $('[data-js-filter]').on('click', function() {
    $('[data-js-filter-input]').focus();
    $('[data-js-filter]').addClass('is-focused');
  });

  $('[data-js-filter-input]').on('focus', function() {
    $('[data-js-filter]').addClass('is-focused');
    $('[data-js-filter-value]').addClass('is-hidden');
  });

  $('[data-js-filter-input]').on('blur', function() {
    $('[data-js-filter]').removeClass('is-focused');
    $('[data-js-filter-value]').removeClass('is-hidden');
  });
});
