window.StoreConnectTheme = window.StoreConnectTheme || {};

// On the Product Show page if there are variants, this prevents you
// selecting a combination of variants that are not available.
window.StoreConnectTheme.VariantHandler = {
  variants: {},
  selectedVariants: {},
  // Shows the price as well as the various summary and other content blocks
  // and also shows the first image
  showVariantDetails: function(self = this) {
    var selectedVariants = self.selectedVariants;
    var ordered_variant_types = $("[data-variant-types-order]").data(
      "variant-types-order"
    );

    // selecting selector from the ordered list of variant types, order by variant type name
    var selector = $.map(ordered_variant_types, function(variantType, indexX) {
      return selectedVariants[variantType];
    }).join(" ");

    $("[data-variant-selector]").hide();
    $("[data-variant-selector='" + selector + "']").show();

    var image_id = $("[data-variant-images]").data("variant-images")[selector],
      image_position = $("[data-product-thumbnail=" + image_id + "]").data(
        "index"
      );
    $("[data-variant-submit-selector]").hide();
    $("[data-variant-submit-selector='" + selector + "']").show();

    //Image position can be 0 so we are explicitly checking undefined / null
    if (image_position === null || image_position === undefined) {
      return true;
    }

    ProductImages.set(image_position);
  },

  resetVariantDetails: function(self = this) {
    // this resets the content block to the master content blocks
    $("[data-variant-selector]").hide();
    $("[data-variant-selector='']").show();

    // this resets the images and thumbnail to the first image (ie, master image)
    ProductImages.set(0);
  },

  enableSubmit: function(self = this) {
    var allSelected = !self.allVariantGroupsSelector().not(".has-selection")
      .length;
    if (allSelected) {
      this.showVariantDetails();
    }
  },

  allVariantOptionSelectorsForGroup: function(group) {
    return $("[data-variant-group='" + group + "'] [data-variant-option]");
  },

  getVariantOptionSelectorForGroup: function(group, variant) {
    return $(
      "[data-variant-group='" +
        group +
        "'] [data-variant-option='" +
        variant +
        "']"
    );
  },

  variantOptionName: function(variantOptionSelector) {
    return $(variantOptionSelector).data("variantOption");
  },

  variantOptionOptions: function(variantOptionSelector) {
    return $(variantOptionSelector).data("variantOptions");
  },

  variantOptionValue: function(variantOptionSelector) {
    return $(variantOptionSelector).val();
  },

  variantOptionCheckedValue: function(variantOptionSelector) {
    return variantOptionSelector.getAttribute("checked") != null;
  },

  variantOptionSelectedValue: function(variantOptionSelector) {
    return variantOptionSelector.getAttribute("selected") != null;
  },

  variantOptionTagName: function(variantOptionSelector) {
    return variantOptionSelector.tagName;
  },

  allVariantGroupsSelector: function() {
    return $("[data-variant-group]");
  },

  getVariantGroupSelector: function(groupName) {
    return $("[data-variant-group='" + groupName + "']");
  },

  variantGroupName: function(variantGroupSelector) {
    return $(variantGroupSelector).data("variantGroup");
  },

  // sets Variant Values from the view to javascript varaible for easier access
  setVariants: function(self = this) {
    var tempVariants = {};
    $.each(self.allVariantGroupsSelector(), function(
      indexX,
      variantGroupSelector
    ) {
      var variantGroupName = self.variantGroupName(variantGroupSelector);
      var variantOptions = {};
      $.each(self.allVariantOptionSelectorsForGroup(variantGroupName), function(
        indexY,
        variantOptionSelector
      ) {
        var variantOptionName = self.variantOptionName(variantOptionSelector);

        variantOptions[variantOptionName] = {};
        variantOptions[
          variantOptionName
        ].checked = self.variantOptionCheckedValue(variantOptionSelector);
        variantOptions[
          variantOptionName
        ].selected = self.variantOptionSelectedValue(variantOptionSelector);
        variantOptions[variantOptionName].disabled = false;
        variantOptions[variantOptionName].options = self.variantOptionOptions(
          variantOptionSelector
        );
        variantOptions[variantOptionName].value = self.variantOptionValue(
          variantOptionSelector
        );
        variantOptions[variantOptionName].tagname = self.variantOptionTagName(
          variantOptionSelector
        );
      });
      tempVariants[variantGroupName] = variantOptions;
    });

    self.variants = tempVariants;
  },

  setViews: function(self = this) {
    $.each(self.variants, function(variantType, variantOptions) {
      self.getVariantGroupSelector(variantType).removeClass("has-selection");

      $.each(variantOptions, function(variantOption, options) {
        var variantOptionSelector = self.getVariantOptionSelectorForGroup(
          variantType,
          variantOption
        )[0];
        $(variantOptionSelector).prop("disabled", options.disabled);
        if (options.tagname == "OPTION") {
          $(variantOptionSelector).prop("selected", options.selected);
          if (options.selected) {
            self.getVariantGroupSelector(variantType).addClass("has-selection");
            self.selectedVariants[variantType] = variantOption;
          }
        }
        if (options.tagname == "INPUT") {
          $(variantOptionSelector).prop("checked", options.checked);
          if (options.checked) {
            self.getVariantGroupSelector(variantType).addClass("has-selection");
            self.selectedVariants[variantType] = variantOption;
          }
        }
      });
    });

    self.enableSubmit();
  },

  disableUnAllowedVariant: function(group, variant, self = this) {
    var currentElement = self.variants[group][variant];
    var currentElementOptions = currentElement.options;

    // loop through each variantoption for the selected type
    $.each(self.variants, function(variantType, variantOptions) {
      $.each(variantOptions, function(variantOption, options) {
        // If selected option has an allowed list for this variantType then
        if (typeof currentElementOptions[variantType] !== "undefined") {
          // disable variant options that are not included in the allowed variants options list for the selected option
          if (currentElementOptions[variantType].length != 0) {
            if (!currentElementOptions[variantType].includes(variantOption)) {
              options.selected = false;
              options.checked = false;
              options.disabled = true;
            }
            // disable variant options that are not included in the allowed variants options list for the selected option
          } else if (currentElementOptions[variantType].length == 0) {
            options.selected = false;
            options.checked = false;
            options.disabled = true;
          }
        }
        // If selected option doesnt have an allowed list for this variantType then
        // disable variant option if selected variant option's variant type is not equal to variant type
        else if (
          typeof currentElementOptions[variantType] == "undefined" &&
          variantType != group
        ) {
          options.selected = false;
          options.checked = false;
          options.disabled = true;
        }
      });
    });
  },

  enableSelectedVariant: function(group, variant, self = this) {
    var currentElement = self.variants[group][variant];

    self.selectedVariants[group] = variant;

    if (currentElement.tagname == "OPTION") {
      currentElement.selected = true;
    }
    if (currentElement.tagname == "INPUT") {
      currentElement.checked = true;
    }
  },

  selectVariant: function(group, variant, self = this) {
    self.disableUnAllowedVariant(group, variant);
    self.enableSelectedVariant(group, variant);

    self.setViews();
  },

  reselectVariant: function(element, self = this) {
    var group = $(element)
      .parents("[data-variant-group]")
      .data("variantGroup");
    var variant = $(element).data("variantOption") || $(element).val();

    var selectedVariants = self.selectedVariants;
    self.resetVariants();
    $.each(selectedVariants, function(key, value) {
      if (key != group) {
        window.StoreConnectTheme.VariantHandler.selectVariant(key, value);
      }
    });

    window.StoreConnectTheme.VariantHandler.selectVariant(group, variant);
  },

  initializeVariants: function(self = this) {
    var dvs = $("[data-variant-selector='']");
    self.hideShowContentTabs(dvs);

    if (self.allVariantGroupsSelector().length > 0) {
      window.StoreConnectTheme.VariantHandler.setVariants();
      window.StoreConnectTheme.VariantHandler.setViews();
      window.StoreConnectTheme.VariantHandler.enableSubmit();

      $("[data-variant-select]").on("change", function() {
        window.StoreConnectTheme.VariantHandler.reselectVariant(this);
      });

      $("[data-variant-option]").on("change", function() {
        window.StoreConnectTheme.VariantHandler.reselectVariant(this);
      });

      $("[data-variant-reset-button]").on("click", function(event) {
        event.preventDefault();
        window.StoreConnectTheme.VariantHandler.resetVariants();
      });
    }
  },

  resetVariants: function(self = this) {
    if (self.allVariantGroupsSelector().length > 0) {
      var tempVariants = self.variants;
      $.each(tempVariants, function(variantType, variantOptions) {
        $('[data-variant-group="' + variantType + '"]').removeClass(
          "has-selection"
        );
        $.each(variantOptions, function(variantOption, options) {
          tempVariants[variantType][variantOption].selected = false;
          tempVariants[variantType][variantOption].checked = false;
          tempVariants[variantType][variantOption].disabled = false;
        });
      });

      self.selectedVariants = {};
      self.setViews();
      self.resetVariantDetails();

      // reset all the data select dropdowns
      $($("[data-variant-select]")).prop("selectedIndex", 0);
      $("[data-variant-submit-selector]").hide();
    }
  },

  hideShowContentTabs: function(dvs) {
    // dvs = data-variant-selector
    dvs.show();
    dvs.each(function() {
      // Accordion_body or TabPanel_body
      var dvs_parent = $(this).parents("[class*=_body]");
      if (dvs_parent.length > 0) {
        if (
          $(this)
            .text()
            .replace(/(' '|\n)/, "").length > 0
        ) {
          dvs_parent.prev("button").show();
        } else {
          dvs_parent.prev("button").hide();
        }
      }
    });
  },
};

$(function() {
  window.StoreConnectTheme.VariantHandler.resetVariants();
  window.StoreConnectTheme.VariantHandler.initializeVariants();
});
