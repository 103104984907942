import Utils from "./utils.js";
import hoverintent from "hoverintent";

const Nav = (function() {
  let lastActiveNode, timeout;

  function closeEventually(e) {
    const INTERVAL = 300;

    timeout = setTimeout(() => e.target.classList.remove('is-active'), INTERVAL);
  }

  function toggleOpen(e) {
    e.preventDefault();
    e.target.parentNode.classList.toggle('is-active');
  }

  function open(e) {
    var target = e.target.parentNode
    clearTimeout(timeout);
    if (lastActiveNode) {lastActiveNode.classList.remove('is-active');}
    target.classList.add('is-active');
    lastActiveNode = target;
  }

  function close(e) {
    var target = e.target.parentNode
    target.classList.remove('is-active')
  }

  document.addEventListener('DOMContentLoaded', () => {
    const isHandheld = Utils.isHandheld(),
          triggers = document.querySelectorAll('[data-meganav-trigger]');

    [...triggers].forEach(trigger => {
      if (isHandheld) {
        trigger.addEventListener('click', toggleOpen);
      } else {
        trigger.parentNode.classList.remove('is-active');
        hoverintent(trigger.parentNode, open, close).options({ timeout: 300 });
        trigger.parentNode.addEventListener('mouseenter', open);
        trigger.parentNode.addEventListener('mouseleave', closeEventually);
      }
    });
  });
}());
