// We are dealing with three image sizes in this component:
// 1. thumbnail
// 2. large - main product image
// 3. huge - gallery for zooming
//
// On the initial load we fetch all the product thumbnails and the
// first product's 'large' image. When the user clicks on a product
// thumbnail we fetch it's 'large' product image. When a user clicks
// on the 'large' product image to open the gallery, which fetch the
// 'huge' product image for zooming.

const ProductImages = (function () {
  let images = [],
      currentIndex = 0,
      hammer;

  function preloadImage(thumbnail) {
    const image = new Image();

    image.src = thumbnail.getAttribute('data-src');
    image.alt = thumbnail.getAttribute('alt');
    image.setAttribute('data-product-image', thumbnail.getAttribute('data-product-thumbnail'));
    image.setAttribute('data-src-zoom', thumbnail.getAttribute('data-src-zoom'));
    images.push(image);
  }

  $(function() {
    if (document.querySelector('[data-product-images]')) {
      ProductImages.init();
    }
  });

  function getZoomImageUrl() {
    const thumbnails = document.querySelectorAll('[data-product-thumbnail]');

    if (thumbnails.length > 0) {
      return images[currentIndex].getAttribute('data-src-zoom');
    } else {
      return document.querySelector('[data-product-image]').getAttribute('data-src-zoom');
    }
  }

  return {
    init: () => {
      const container = document.querySelector('[data-product-images=true] [data-product-image-container]'),
            thumbnails = document.querySelectorAll('[data-product-images=true] [data-product-thumbnail]');

      if (container !== null) {
        // TODO: Clean this up, we are not using turbolinks anymore
        // Reset image array and current index
        // Necessary due to turbolinks
        images = [];
        currentIndex = 0;
        hammer = new Hammer(container);

        if (thumbnails.length > 0) {
          hammer.on('swiperight', ProductImages.prev);
          hammer.on('swipeleft', ProductImages.next);

          [...thumbnails].forEach(thumbnail => {
            // Initially we load the first hero product image only
            // The thumbnail's data attributes contain the urls for the
            // larger images, which we store and load when needed.
            preloadImage(thumbnail);

            // Set new image when a thumbnail is clicked
            thumbnail.addEventListener('click', () => {
              const index = thumbnail.getAttribute('data-index');
              ProductImages.set(index)
            });
          });
        }

        container.addEventListener('mouseover', ProductImages.zoom);
        container.addEventListener('mouseleave',ProductImages.reset);
        container.addEventListener('mousemove',ProductImages.move);
      }
    },

    zoom: () => {
      const image = document.querySelector('[data-product-image]');

      image.classList.add('is-zoomed');
      image.setAttribute('src', getZoomImageUrl());
    },

    reset: () => {
      const image = document.querySelector('[data-product-image]');

      image.classList.remove('is-zoomed');
      image.setAttribute('style', 'transform: scale(1)');
    },

    move: (e) => {
      const image = document.querySelector('[data-product-image]'),
            centerX = e.target.clientWidth/2,
            centerY = e.target.clientHeight/2,
            x = centerX - e.offsetX,
            y = centerY - e.offsetY;

      image.setAttribute('style',
        'transform: translateX(' + x + 'px) translateY(' + y + 'px) scale(2)'
      );
    },

    set: (index) => {
      const image = document.querySelector('[data-product-images=true] [data-product-image]'),
            thumbnails = document.querySelectorAll('[data-product-images=true] [data-product-thumbnail]');

      // Check if the index to be set is valid
      if (index >= images.length || index < 0) {return;}

      // Replace the existing image from array of preloaded images
      // and update the active state for all thumbnails
      currentIndex = index;

      image.replaceWith(images[index]);

      if (thumbnails.length > 0) {
        [...thumbnails].forEach(t => {
          if (t.getAttribute('data-index') == index) {
            t.classList.add('is-active');
          } else {t.classList.remove('is-active');}
        });
      }
    },

    next: (e) => ProductImages.set(currentIndex + 1),

    prev: (e) => ProductImages.set(currentIndex - 1)
  }
}());
