$(function() {

  // TODO: Clean this up, we are not using turbolinks anymore
  // TODO: Non-ideal fix to ensure the slider plays well with
  // turbolinks/element caching
  if ($('.irs').length > 0) { $('.irs').remove(); }

  $('[data-js-range-slider]').ionRangeSlider({
    onFinish: function (data) {
      var filter_on_finish = $('[data-js-range-slider]').data('js-filter-on-finish'),
          isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;

      $('[data-js-range-min]').val(data.from);
      $('[data-js-range-max]').val(data.to);

      if (!isMobile) {
        if (filter_on_finish) {$('#filters').submit();}
      }
    },
  });
});
