$(function() {
  if ($('[data-js-tab-panel]').length > 0) {

    // Activate the first tab if none are already active.
    var $tabs_active_by_default = $('[data-js-tab-trigger]').filter('.is-active');

    if ($tabs_active_by_default.length === 0) {
      $('[data-js-tab-trigger]').first().addClass('is-active');
    }
    $('[data-js-tab-trigger]').last().addClass('is-last');
    $('[data-js-tab-panel]').last().addClass('is-last');
  }

  $('[data-js-tab-trigger]').on('click', function() {
    var isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;

    if (isMobile) {
      $(this).toggleClass('is-active');
    } else {
      $('[data-js-tab-trigger]').removeClass('is-active');
      $(this).addClass('is-active');
    }
  });
});
