(function () {
  document.addEventListener('DOMContentLoaded', () => {
    const elems = document.querySelectorAll('[data-datetime]');

    [...elems].map(elem => localize(elem));
  });

  function localize(elem) {
    var dateTimeStr = elem.getAttribute('data-datetime')
    if(dateTimeStr == '') { return false }

    var overrideFormat = elem.getAttribute('data-datetime-format')

    var dateFormat = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }
    var timeFormat = {
      hour: 'numeric',
      minute: 'numeric',
    }

    switch (overrideFormat) {
      case "date":
        var format = {
          ...dateFormat
        }
        break;
      case "time":
        var format = {
          ...timeFormat
        }
        break;
      default:
        var format = {
          ...dateFormat,
          ...timeFormat
        }
    }

    var formatter = new Intl.DateTimeFormat(
      Intl.DateTimeFormat().resolvedOptions().locale, format)

    var outputString = formatter.format(Date.parse(dateTimeStr));

    // TODO: replace with proper formatting options
    if(overrideFormat == "special") {
      var outputString = outputString.replaceAll("/","-")
    }

    elem.innerHTML = outputString

    elem.setAttribute('title', Intl.DateTimeFormat().resolvedOptions().timeZone);
  }
})();
