const Utils = (function() {
  let isHandheld = null;

  $(function() {
    if (document.querySelector('[data-handheld]') === null) {
      const element = document.createElement('div');

      element.setAttribute('data-handheld', '');
      document.querySelector('body').append(element);
    }
  });

  return {
    isHandheld: () => {
      const element = document.querySelector('[data-handheld]');

      // Return value if already stored to avoid DOM query
      if (isHandheld) {return isHandheld;}

      if (window.getComputedStyle(element).display === 'none') {
        isHandheld = false;
      } else {
        isHandheld = true;
      }

      return isHandheld;
    }
  }
}());

export default Utils;
