const Slideshow = (function () {
  const DEFAULT_PARAMS = {
    autoplay: true,
    autoplayHoverPause: true,
    autoplayButtonOutput: false,
    arrowKeys: true,
    autoHeight: true,
    center: true,
    controlsText: ['', '']
  }

  let slideshows = [];

  // Check if the page has slideshows before intialising them
  $(() => {
    if (document.querySelectorAll('[data-slideshow]')) {
      [...document.querySelectorAll('[data-slideshow]')].map(slideshow => {
        Slideshow.init('[data-slideshow=' + slideshow.getAttribute('data-slideshow') + ']');
      });
    }
  })

  return {
    init: (selector, params) => {
      const container = {container: selector},
            slideshow = tns({...DEFAULT_PARAMS, ...params, ...container});

      let resizeId;

      slideshows.push(slideshow);
      window.addEventListener('resize', () => {
        clearTimeout(resizeId);
        resizeId = setTimeout(() => {
          slideshow.updateSliderHeight();
        }, 300);
      });
    }
  }
})();
